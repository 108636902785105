import React from 'react';
import Layout, { Content } from '../../components/Layout';
import Navigation from '../../components/mybunac/navigation';
import { useAuth } from '../../hooks/useAuth';
import Form from '../../components/forms/Profile';
import { Login, NotVerified } from '../../components/login';

const Profile =  ({ data, pageContext }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated) {
    return <Login />;
  }

  if (!user.email_verified) {
    return <NotVerified />;
  }

  return (
    <Layout title={'Profile'}>
      <Navigation />
      <Content>
        <Form />
      </Content>
    </Layout>
  );
};

export default Profile