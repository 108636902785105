import React from 'react';
import Layout, { Content } from '../../components/Layout';
import HeroHeader from '../../components/heroheader';
import Navigation from '../../components/mybunac/navigation';
import Button from '../../components/common/Button';
import { useAuth } from '../../hooks/useAuth';
import Text from '../../components/common/Text';

export default ({ data, pageContext }) => {
  const { login } = useAuth();

  return (
    <Layout title={'Login'}>
      <HeroHeader title="MyBUNAC" backgroundUrl="/images/mybunac.jpg" />
      <Navigation />
      <Content>
        <Text>You need an active account to access MyBUNAC</Text>
        <Button submit onClick={login}>
          Please Login or Sign Up to continue
        </Button>
      </Content>
    </Layout>
  );
};
