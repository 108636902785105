import React, { useState, useEffect } from 'react';
import Form from './Form';
import ThankYou from './ThankYou';
import { useMyBunac } from '../../hooks/useMyBunac';
import { FIELD_TYPES, DATA_MODELS } from './Form/Field';

const FORM_ID = 'profile';

const formConfig = [
  {
    label: 'Title',
    name: 'Title',
    model: DATA_MODELS.person,
  },
  {
    label: 'First Name',
    name: 'First Name',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: '(Middle Names)',
    name: 'Middle Names',
    model: DATA_MODELS.person,
  },
  {
    label: 'Last Name',
    name: 'Name',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Phone number',
    name: 'Phone',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Email Address',
    name: 'Email',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Date of Birth',
    name: 'Date of Birth',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Nationality (from your passport)',
    name: 'Nationality (from your passport)',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  // ADDRESS
  {
    label: 'Line 1',
    name: 'Address Line 1',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Line 2',
    name: 'Address Line 2',
    model: DATA_MODELS.person,
  },
  {
    label: 'Town',
    name: 'Town',
    model: DATA_MODELS.person,
  },
  {
    label: 'State/County',
    name: 'State/County',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Zip/Postal code',
    name: 'ZIP / Postal Code',
    model: DATA_MODELS.person,
  },
  {
    label: 'Country',
    name: 'Country',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Emergency Contact Name',
    name: 'Emergency Contact Name',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label: 'Emergency Contact phone number',
    name: 'Emergency Contact phone No.',
    isRequired: true,
    model: DATA_MODELS.person,
  },
  {
    label:
      'BUNAC will only use these details to contact you about your work abroad booking. Please see the BUNAC privacy policy for more information.  You can manage your marketing communication preferences below.',
    type: FIELD_TYPES.lineOfText,
    style: {
      marginTop: '10px',
      marginBottom: '10px',
    },
  },
  {
    label: 'Can we contact you via email?',
    name: 'Communication Consent Email',
    model: DATA_MODELS.person,
  },
  {
    label: 'Can we contact you via SMS?',
    name: 'Communication Consent SMS',
    model: DATA_MODELS.person,
  },
  {
    label: 'Can we contact you via phone?',
    name: 'Communication Consent Phone',
    model: DATA_MODELS.person,
  },
  {
    label:
      'To give you the best online customer experience, we would like to pass your data onto third parties such as Facebook and Instagram. Are you OK for us to pass your data onto third parties?',
    name: 'Communication Consent Social',
    model: DATA_MODELS.person,
  },
];

const breadcrumbs = [{ label: 'Profile' }];

const Profile = () => {
  const [submitted, setSubmited] = useState(false);
  const { profile } = useMyBunac();
  const [initValues, setInitValues] = useState({});

  useEffect(() => {
    if (!profile) {
      return setInitValues({});
    }

    setInitValues({
      ...profile,
      phone: profile.phone ? profile.phone[0].value : undefined,
      email: profile.email ? profile.email[0].value : undefined,
    });
  }, [profile]);

  const submit = () => {
    setSubmited(true);
  };

  if (submitted) {
    return (
      <ThankYou
        title="Updated Information"
        text="You information was successfully updated"
      />
    );
  }

  return (
    <Form
      headline="Your Profile"
      formId={FORM_ID}
      config={formConfig}
      initValues={initValues}
      onSubmit={submit}
      breadcrumbs={breadcrumbs}
    />
  );
};

export default Profile;