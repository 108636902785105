import React, { useState, useEffect } from 'react';
import Layout, { Content } from '../../components/Layout';
import HeroHeader from '../../components/heroheader';
import Navigation from '../../components/mybunac/navigation';
import Button from '../../components/common/Button';
import { useAuth } from '../../hooks/useAuth';
import Text from '../../components/common/Text';

export default ({ data, pageContext }) => {
  const { user, login } = useAuth();
  const [queryParams, setQueryParams] = useState({});
  const [
    message,
    setMessage,
  ] = useState(`We sent you an email to verify your address, please check your inbox.

  You need an active account to access MyBUNAC`);

  useEffect(() => {
    const queryParams = {};
    const keyPairs = window.location.search.substr(1).split('&');
    for (const key in keyPairs) {
      var split = keyPairs[key].split('=');
      queryParams[decodeURIComponent(split[0])] = decodeURIComponent(split[1]);
    }

    setQueryParams(queryParams);
  }, []);

  useEffect(() => {
    if (queryParams.message === 'This URL can be used only once') {
      setMessage('Thank you for verifying your email address!');
    }
  }, [queryParams]);

  return (
    <Layout title={'Not verified'}>
      <HeroHeader title="MyBUNAC" backgroundUrl="/images/mybunac.jpg" />
      <Navigation />
      <Content>
        <Text>{message}</Text>
        <Button submit onClick={login}>
          Please Login to continue
        </Button>
      </Content>
    </Layout>
  );
};
